import store from "@/store";
import { authAPI } from "@/api/auth";
import jwt from "@/api/jwt";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import {
  VuexModule,
  Module,
  Action,
  Mutation,
  getModule,
} from "vuex-module-decorators";

interface User {
  username: string;
  rejectsAdvertising: boolean;
}

@Module({ namespaced: true, dynamic: true, store, name: "User" })
class UserModule extends VuexModule {
  user: User = {
    username: "",
    rejectsAdvertising: false
  };
  error = "";
  updateComponentUser = 0;

  get fullName() {
    return this.user.username;
  }

  @Mutation
  [Mutations.SET_USER](user: User) {
    this.user = user;
    jwt.saveToken("username", this.user.username);
  }

  @Mutation
  [Mutations.INCREMENT_COMPONENT_USER_KEY]() {
    this.updateComponentUser++;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.error = error;
  }

  @Action
  async [Actions.FETCH_USER](): Promise<void> {
    try {
      const refresh = await authAPI.refreshToken();
      jwt.saveToken("authToken", refresh["accessToken"]);
      const { data } = await authAPI.user();
      this.context.commit(Mutations.SET_USER, data.user);
      this.context.commit(Mutations.INCREMENT_COMPONENT_USER_KEY);
    } catch ({response}) {
      if (response) {
        this.context.commit(Mutations.SET_ERROR, response.data.meta.message.text);
      } else {
        this.context.commit(Mutations.SET_ERROR, "Error");
      }
    }
  }

  @Action
  async [Actions.UPDATE_PROFILE](values): Promise<void> {
    try {
      const refresh = await authAPI.refreshToken();
      jwt.saveToken("authToken", refresh["accessToken"]);
      const { data } = await authAPI.updateUser(values);
      this.context.commit(Mutations.SET_USER, data.user);
      this.context.commit(Mutations.INCREMENT_COMPONENT_USER_KEY);
    } catch ({response}) {
      if (response) {
        this.context.commit(Mutations.SET_ERROR, response.data.meta.message.text);
      } else {
        this.context.commit(Mutations.SET_ERROR, "Error");
      }
    }
  }
}

export default getModule(UserModule);
