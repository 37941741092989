import store from "@/store";
import { authAPI } from "@/api/auth";
import jwt from "@/api/jwt";
import {
  Module,
  Action,
  Mutation,
  VuexModule,
  getModule,
} from "vuex-module-decorators";
import { Actions, Mutations } from "@/store/enums/StoreEnums";

interface CustomerInfo {
  error: unknown;
}

export interface Customers extends Array<Customer> {
  [index: number]: Customer;
}

export interface Customer {
  [key: string]: string | Record<string, unknown>;
  invoiceAddress: {
    [key: string]: string;
  }
}

@Module({ namespaced: true, dynamic: true, store, name: "Customer" })
class CustomersModule extends VuexModule implements CustomerInfo {
  customers: Customers = [];
  customer = {} as Customer;
  iframeUrl = "";
  error = {};
  lang = '';

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.error = error;
  }

  @Mutation
  [Mutations.SET_AUTH](accessResponse: { accessToken: string }) {
    jwt.saveToken("docsToken", accessResponse.accessToken);
  }

  @Mutation
  [Mutations.SET_CUSTOMERS](customers) {
    this.customers = customers.data.customers;
  }
  
  @Mutation
  [Mutations.SET_CUSTOMER](customer: Customer) {
    this.customer = customer;
  }
  
  @Mutation
  [Mutations.SET_DOCUMENTS](documents) {
    this.iframeUrl = documents.ssoUrl;
  }
  
  @Mutation
  [Mutations.SET_LANG](lang: string) {
    this.lang = lang;
  }

  @Action
  async [Actions.FETCH_CUSTOMERS]() {
    try {
      const refresh = await authAPI.refreshToken();
      jwt.saveToken("authToken", refresh["accessToken"]);
      const data = await authAPI.fetchCustomers();
      this.context.commit(Mutations.SET_CUSTOMERS, data);
    } catch ({ response }) {
      this.context.commit(Mutations.SET_ERROR, response.data.error);
    }
  }

  @Action
  async [Actions.FETCH_DOCUMENTS](id: string) {
    try {
      const refresh = await authAPI.refreshToken();
      jwt.saveToken("authToken", refresh["accessToken"]);
      const accessResponse = await authAPI.accessTo("up-documents");
      this.context.commit(Mutations.SET_AUTH, accessResponse);
      const data = await authAPI.fetchDocuments(id);
      this.context.commit(Mutations.SET_DOCUMENTS, data);
    } catch ({ response }) {
      this.context.commit(Mutations.SET_ERROR, response.data.error);
    }
  }

  @Action
  async [Actions.SWITCH_LANG](lang: string) {
    this.context.commit(Mutations.SET_LANG, lang);
  }
}

export default getModule(CustomersModule);
